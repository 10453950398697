.app {
  overflow-y: auto;
  height: calc(100vh - 50px)
}

.content {
  display: flex;
  justify-content: center;
  min-width: 800px;
}

.allNotes {
  max-width: 875px;
}

.searchHeader {
  margin-bottom: 5px;
}

.filterContainer {
  display: flex;
  justify-content: center;
}

.filters {
  padding-left: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.notesTable {
  table-layout: fixed;
  border-collapse: collapse;
  width: 900px;
}

.filterDetail {
  text-align: center;
  margin-right: 20px;
  font-size: large;
}

th {
  height: 25px;
  max-height: 25px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  font-size: 20px;
  padding: 8px;
  vertical-align: middle;
}

.gameHeader {
  text-align: left;
}

.completionHeader {
  font-size: 16px;
}

td {
  padding: 0px;
  border-right: none;
}
