.avoid,
.unobtainable,
.accessibility,
.consoleCommands {
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  font-weight: 700;
}

.avoid {
  color: #ffa600;
}

.unobtainable {
  color: #ff0000;
}

.accessibility {
  color: #2600ff;
}

.consoleCommands {
  color: #294413;
}

.line {
  padding-bottom: 10px;
}

td {
  white-space: pre-wrap; /* css */
  white-space: -moz-pre-wrap; /* Mozilla */
  white-space: -pre-wrap; /* Chrome*/
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.gameTitleContainer {
  display: flex;
  margin: 5px;
}

.chevronDown {
  margin-top: 2px;
}

.taGamePage {
  padding-left: 10px;
  margin-left: 0px;
  margin-bottom: 10px;
  width: 152px;
}

.gameTitle {
  padding-right: 5px;
  font-size: 20px;
}

.gameTitle,
.chevronDown {
  padding-top: 12px;
}

.gameDifficulty {
  font-size: 20px;
}

.chevronDown,
.gameTitle:hover {
  cursor: pointer;
  text-decoration: underline;
}

.gameDetails {
  display: flex;
  align-items: flex-end;
}

.externalTAPage {
  padding: 3px;
  padding-bottom: 1px;
}

.externalLink:hover {
  cursor: pointer;
}

.gameDifficulty,
.walkthrough,
.tagsContainer {
  text-align: center;
}

.tagsContainer {
  padding-left: 10px;
  padding-right: 10px;
}

.tableRowGameData {
  border-top: 1px solid #cfcfcf;
}

.noteContentContainer {
  margin-left: 55px;
}

.noteContent {
  margin: 0px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.gameImg {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 3px;
  border: 1px solid #000;
}