.nav {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 2px #000;
  color: #fff;
  background-color: #26252e;
  min-width: 800px;
}

.leftNav {
  align-items: baseline;
}

.navLink {
  text-decoration: none;
  margin-left: 15px;
}

.subSection:hover {
  text-decoration: underline;
}

.title {
  font-size: 18px;
}
